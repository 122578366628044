import React from "react"
import Layout from "../components/layout"
import LoomVideo from "../components/loom-video/loom-video"
import SEO from "../components/seo"

const ShapeInAction = () => {
  return (
    <Layout>
      <SEO title="Shape in Action" />
      <LoomVideo />
    </Layout>
  )
}

export default ShapeInAction