import React, { useState } from 'react'
import './loom-video.scss'
import Arrow from "../../images/shape-in-action/Mascot.svg"
import Share from "../../images/shape-in-action/share 1.svg"
import Linkedin from "../../images/shape-in-action/linkedin (1) 1.svg"
import Whatsapp from "../../images/shape-in-action/social 1.svg"
import Email from "../../images/shape-in-action/link 3.svg"
import CopyLink from "../../images/shape-in-action/link 1.svg"
import Video from "../../images/shape-in-action/SHAPE - 24 September 2024.mp4"
import Preview from "../../images/video-preview.png"
import { Link } from 'gatsby'

const LoomVideo = () => {

  const [showTooltip, setShowTooltip] = useState(false)
  const videoUrl = 'https://www.loom.com/embed/13607ee1b0ea4a6fa4596b14d8257403?sid=5c4ebc15-f470-4542-bd8f-80e5dfd26899'

  const handleShare = (platform, videoUrl, title) => {
    const encodedUrl = encodeURIComponent(videoUrl);
    const encodedTitle = encodeURIComponent(title);
  
    let shareUrl = '';
    if (platform === 'whatsapp') {
      shareUrl = `https://wa.me/?text=${encodedTitle}%20${encodedUrl}`;
    } else if (platform === 'linkedin') {
      shareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodedUrl}`;
    } else if (platform === 'email') {
      const emailBody = `Check out this video: ${videoUrl}`;
      shareUrl = `mailto:?subject=${encodedTitle}&body=${encodeURIComponent(emailBody)}`;
    }
  
    window.open(shareUrl, '_blank');
  };

  const handleCopyLink = (videoUrl) => {
    navigator.clipboard.writeText(videoUrl).then(() => {
      setShowTooltip(true);
      setTimeout(() => setShowTooltip(false), 2000);
    }).catch((err) => {
      console.error('Failed to copy link:', err);
    });
  };

  return (
    <div className='loomPageWrapper'>
      <div className='videoWrapper'>
        <div className='loomHeader'>
          <h1>SHAPE in 5 mins</h1>
          <p>Everything from onboarding to reporting to action.<span className="break"> </span> See it here.</p>
        </div>

        <div className='video'>
          <video playsInline controls loading="lazy" poster={Preview}>
            <source src={Video} type="video/mp4" />
            Sorry, your browser doesn't support videos.
          </video>
        </div>

        <div className='shareBoxWrapper'>
          <div className='shareBox'>
            <div>
              <img src={Share} alt='share' />
              <span>Share</span>
            </div>
            <div className='shareLinks'>
              <button onClick={() => handleShare('linkedin', videoUrl, 'Shape in Action')}>
                <img src={Linkedin} alt='linkedin' />
              </button>
              <button onClick={() => handleShare('whatsapp', videoUrl, 'Shape in Action')}>
                <img src={Whatsapp} alt='whatsapp' />
              </button>
              <button onClick={() => handleShare('email', videoUrl, 'Shape in Action')}>
                <img src={Email} alt='email' />
              </button>
              <button onClick={() => handleCopyLink(videoUrl)}>
                <img src={CopyLink} alt='copy link' />
              </button>
              {showTooltip && (
                <div className='copy-tooltip'>
                  Copied!
                </div>
              )}
            </div>
          </div>  
        </div>

        <div className='contentWrapper'>
          <h1>Want to see more?</h1>
          <p>Take the next step.</p>
        </div>

        <div className='scheduleSessionBox'>
          <img src={Arrow} alt='schedule' />
          <h1>Schedule a strategy session</h1>
          <p>Get customised advice and strategies to implement SHAPE within your organisation.</p>
          <Link className='setupCallButton' to='/demo'>
            Set up a call
          </Link>
        </div>

      </div>
      
    </div>
  )
}

export default LoomVideo